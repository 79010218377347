import type { AxiosError } from "axios";

import type { Asset, AssetCategory } from "@/Dashboard/pages/AssetLibrary/types";
import type { ExperienceAssetCategory } from "@/VisualBuilder/components/LeftHandMenu/types";
import { REQUEST_STATUSES } from "@/constants";
import { getErrorResponse } from "@/utils/helper";

import { api } from "./instance";

export class AssetService {
  static async getAsset(propertyId: number, assetId: number) {
    try {
      const resp = await api({
        method: "get",
        url: `/api/v2/assets/${propertyId}/${assetId}`,
      });

      return {
        resp: resp.data as Asset,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: null,
        err: getErrorResponse(err as AxiosError) as unknown,
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async getAssets(propertyId: number, query: unknown) {
    try {
      const resp = await api({
        method: "get",
        url: `/api/v2/assets/${propertyId}`,
        params: query,
      });

      return {
        resp: resp.data as { data: Asset[]; count: number },
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: { data: [], count: 0 },
        err: getErrorResponse(err as AxiosError) as unknown,
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async getAssetCategories(propertyId: number) {
    try {
      const resp = await api({
        method: "get",
        url: `/api/v2/asset-categories/${propertyId}`,
      });

      return {
        resp: resp.data as { data: AssetCategory[]; count: number },
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        err: getErrorResponse(err as AxiosError) as unknown,
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  /**
   */
  static async updateAssetCategory(propertyId: number, categoryId: number, data: unknown) {
    try {
      const resp = await api({
        method: "put",
        url: `/api/v2/asset-categories/${propertyId}/${categoryId}`,
        data,
      });

      return {
        resp: resp.data as AssetCategory,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        err: getErrorResponse(err as AxiosError) as unknown,
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async addAsset(propertyId: number, data?: unknown) {
    try {
      const resp = await api({
        method: "post",
        url: `/api/v2/assets/${propertyId}`,
        data,
      });

      return {
        resp: resp.data as Asset,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        err: getErrorResponse(err as AxiosError) as unknown,
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  /**
   */
  static async getAssetCategory(propertyId: number, assetCategoryId: number) {
    try {
      const resp = await api({
        method: "get",
        url: `/api/v2/asset-categories/${propertyId}/${assetCategoryId}`,
      });

      return {
        resp: resp.data as AssetCategory,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        err: getErrorResponse(err as AxiosError) as unknown,
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async addAssetCategory(propertyId: number, data?: unknown) {
    try {
      const resp = await api({
        method: "post",
        url: `/api/v2/asset-categories/${propertyId}`,
        data,
      });

      return {
        resp: resp.data as AssetCategory,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        err: getErrorResponse(err as AxiosError) as unknown,
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async deleteAssetCategories(propertyId: number, assetCategoryIds: number[]) {
    try {
      const resp = await api({
        method: "delete",
        url: `/api/v2/asset-categories/bulk/delete/${propertyId}`,
        params: { assetCategoryIds },
      });

      return {
        resp: resp.data as unknown,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        err: getErrorResponse(err as AxiosError) as unknown,
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async moveAssets(propertyId: number, categoryId: number, assetCategoryIds: number[]) {
    try {
      const resp = await api({
        method: "put",
        url: `/api/v2/assets/move/${propertyId}/${categoryId}`,
        data: { ids: assetCategoryIds },
      });

      return {
        resp: resp.data as unknown,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        err: getErrorResponse(err as AxiosError) as unknown,
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }

  static async addAssetCategoryToExperience(
    propertyId: number,
    experienceId: number,
    assetCategoryId: number,
    data?: unknown,
  ) {
    try {
      const resp = await api({
        method: "post",
        url: `/api/v2/asset-categories/experience/${propertyId}/${experienceId}/${assetCategoryId}`,
        data,
      });

      return {
        resp: resp.data as ExperienceAssetCategory,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        err: getErrorResponse(err as AxiosError) as unknown,
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }
  static async updateExperienceAssetCategory(
    propertyId: number,
    experienceId: number,
    assetCategoryId: number,
    data?: unknown,
  ) {
    try {
      const resp = await api({
        method: "put",
        url: `/api/v2/asset-categories/experience/${propertyId}/${experienceId}/${assetCategoryId}`,
        data,
      });

      return {
        resp: resp.data as ExperienceAssetCategory,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        err: getErrorResponse(err as AxiosError) as unknown,
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }
}
