import { elementMediaItemsReducer } from "@/store/v2/elementMediaItems/reducer.js";
import organizationLinkedPropertiesReducer from "@/store/v2/organizationLinkedProperties/organizationLinkedPropertiesSlice";

import authReducer from "./auth/auth.slice";
import dialogs from "./dialogs/reducer";
import { persistentValuesReducer } from "./persistentValues/reducer";
import { simpleValuesReducer } from "./simpleValues/reducer";
// v2
import { assetCategoriesReducer } from "./v2/assetCategories/reducer";
import { assetFiltersReducer } from "./v2/assetFilters/reducer";
import { assetSelectionReducer } from "./v2/assetSelection/reducer";
import { assetsReducer } from "./v2/assets/reducer";
import { checkUrlsReducer } from "./v2/checkUrls/reducer";
import { coachesReducer } from "./v2/coaches/reducer";
import { contactNotesReducer } from "./v2/contactNotes/reducer";
import { contactsReducer } from "./v2/contacts/reducer";
import { contentElementsReducer } from "./v2/contentElements/reducer";
import { contentTypesReducer } from "./v2/contentTypes/reducer";
import { currentAssetReducer } from "./v2/currentAsset/reducer";
import { currentAssetCategoryReducer } from "./v2/currentAssetCategory/reducer";
import { currentContactReducer } from "./v2/currentContact/reducer";
import { currentElementReducer } from "./v2/currentElement/reducer";
import { currentExperienceReducer } from "./v2/currentExperience/reducer";
import { currentExperiencesReducer } from "./v2/currentExperiences/reducer";
import { currentMediaItemReducer } from "./v2/currentMediaItem/reducer";
import { currentMediaShareReducer } from "./v2/currentMediaShare/reducer";
import currentOrganizationReducer from "./v2/currentOrganization/currentOrganizationSlice";
import { currentPropertyReducer } from "./v2/currentProperty/reducer";
import { currentSelectedItemsReducer } from "./v2/currentSelectedItems/reducer";
import { currentShareReducer } from "./v2/currentShare/reducer";
import { currentSharedExperienceReducer } from "./v2/currentSharedExperience/reducer";
import { currentUnivVideoReducer } from "./v2/currentUnivVideo/reducer";
import { currentUserReducer } from "./v2/currentUser/reducer";
import { currentUsersReducer } from "./v2/currentUsers/reducer";
import { elementsReducer } from "./v2/elements/reducer";
import { elementsDialogReducer } from "./v2/elementsDialog/reducer";
import { experienceMediaItemsReducer } from "./v2/experienceMediaItems/reducer";
import { experienceMembersReducer } from "./v2/experienceMembers/reducer";
import { experiencesReducer } from "./v2/experiences/reducer";
import experiencesV2Reducer from "./v2/experiencesV2/experiencesV2Slice";
import { flagsReducer } from "./v2/flags/reducer";
import { hotspotDataReducer } from "./v2/hotspotData/reducer";
import { hotspotsReducer } from "./v2/hotspots/reducer";
import { iconsReducer } from "./v2/icons/reducer";
import { loadingStatusReducer } from "./v2/loadingStatus/reducer";
import { membersReducer } from "./v2/members/reducer";
import { messagesReducer } from "./v2/messages/reducer";
import { notificationsReducer } from "./v2/notifications/reducer";
import organizationExperiencesData from "./v2/organizationExperiences/organizationExperiencesSlice";
import organizationUsersData from "./v2/organizationUsers/organizationUsersSlice";
import { organizationsReducer } from "./v2/organizations/reducer";
import { propertiesReducer } from "./v2/properties/reducer";
import { propertyOrganizationValuesReducer } from "./v2/propertyOrganizations/reducer";
import { queriesReducer } from "./v2/queries/reducer";
import { recentExperiencesReducer } from "./v2/recentExperiences/reducer";
import { searchOptionsReducer } from "./v2/searchOptions/reducer";
import { searchedExperiencesReducer } from "./v2/searchedExperiences/reducer";
import { singleShareExperienceReducer } from "./v2/singleShareExperience/reducer";
import { slideToIndexReducer } from "./v2/swiper/reducer";
import { tagsReducer } from "./v2/tags/reducer";
import { templateExperiencesReducer } from "./v2/templateExperiences/reducer";
import { univCategoriesReducer } from "./v2/univCategories/reducer";
import { univCategoryVideosReducer } from "./v2/univCategoryVideos/reducer";
import { univVideosReducer } from "./v2/univVideos/reducer";
import { uploadProgressReducer } from "./v2/uploadProgress/reducer";
import { usersReducer } from "./v2/users/reducer";
import { visualBuilderDataReducer } from "./v2/visualBuilderData/reducer";
import { visualBuilderValuesReducer } from "./v2/visualBuilderValues/reducer";
import globalTabsData from "../VisualBuilder/components/GlobalTabs/GlobalTabsDataSlice";
import mainHeaderData from "../VisualBuilder/components/MainHeader/MainHeaderDataSlice";

const appReducers = {
  auth: authReducer,
  dialogs,
  persistentValues: persistentValuesReducer,
  simpleValues: simpleValuesReducer,
  // visualBuilderData,
  mainHeaderData,
  globalTabsData,
  // v2
  queries: queriesReducer,
  properties: propertiesReducer,
  coaches: coachesReducer,
  users: usersReducer,
  experiences: experiencesReducer,
  experiencesV2: experiencesV2Reducer,
  members: membersReducer,
  icons: iconsReducer,
  contacts: contactsReducer,
  experienceMediaItems: experienceMediaItemsReducer,
  elements: elementsReducer,
  elementsDialog: elementsDialogReducer,
  hotspots: hotspotsReducer,
  contentElements: contentElementsReducer,
  currentProperty: currentPropertyReducer,
  currentExperience: currentExperienceReducer,
  singleShareExperience: singleShareExperienceReducer,
  currentExperiences: currentExperiencesReducer,
  currentMediaItem: currentMediaItemReducer,
  currentOrganization: currentOrganizationReducer,
  currentElement: currentElementReducer,
  currentShare: currentShareReducer,
  currentUser: currentUserReducer,
  currentUsers: currentUsersReducer,
  univCategories: univCategoriesReducer,
  univVideos: univVideosReducer,
  univCategoryVideos: univCategoryVideosReducer,
  currentUnivVideo: currentUnivVideoReducer,
  currentContact: currentContactReducer,
  contactNotes: contactNotesReducer,
  messages: messagesReducer,
  visualBuilderValues: visualBuilderValuesReducer,
  notifications: notificationsReducer,
  recentExperiences: recentExperiencesReducer,
  templateExperiences: templateExperiencesReducer,
  searchedExperiences: searchedExperiencesReducer,
  checkUrls: checkUrlsReducer,
  currentSelectedItems: currentSelectedItemsReducer,
  currentSharedExperience: currentSharedExperienceReducer,
  uploadProgress: uploadProgressReducer,
  tags: tagsReducer,
  experienceMembers: experienceMembersReducer,
  contentTypes: contentTypesReducer,
  propertyOrganizationValues: propertyOrganizationValuesReducer,
  visualBuilderData: visualBuilderDataReducer,
  searchOptions: searchOptionsReducer,
  hotspotData: hotspotDataReducer,
  flags: flagsReducer,
  elementMediaItems: elementMediaItemsReducer,
  slideToIndex: slideToIndexReducer,
  loadingStatus: loadingStatusReducer,
  assetCategories: assetCategoriesReducer,
  currentAssetCategory: currentAssetCategoryReducer,
  assets: assetsReducer,
  assetSelection: assetSelectionReducer,
  assetFilters: assetFiltersReducer,
  currentAsset: currentAssetReducer,
  organizations: organizationsReducer,
  organizationLinkedProperties: organizationLinkedPropertiesReducer,
  currentMediaShare: currentMediaShareReducer,
  organizationExperiences: organizationExperiencesData,
  organizationUsers: organizationUsersData,
};

export default appReducers;
