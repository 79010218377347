import { REQUEST_STATUSES } from "@/constants/index.js";

import * as types from "./types.js";
import { getNewList } from "../../../utils/helper.js";

const initialState = {
  icons: [],
  status: REQUEST_STATUSES.IDLE,
  err: null,
};

export const iconsReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_ICONS: {
      const { propertyId, icons, status, err, query, count } = action.payload;
      const newIcons = getNewList(query, state[propertyId].icons, icons);

      return {
        ...state,
        [propertyId]: {
          icons: newIcons,
          count,
          status,
          err,
          noResults: !newIcons.length,
        },
      };
    }
    case types.SET_ICONS_REQUEST_STATUS: {
      const { propertyId, status } = action.payload;

      return {
        ...state,
        [propertyId]: { ...(state[propertyId] || {}), status },
      };
    }
    case types.RESET_ICONS: {
      const { propertyId } = action.payload;

      if (propertyId) {
        return { ...state, [propertyId]: initialState };
      }

      return {};
    }
    default:
      return state;
  }
};
