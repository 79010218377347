import type { PaletteColorOptions } from "@mui/material";

const BASE = {
  0: "#FFFFFF",
  1000: "#000000",
};

const AZURE = {
  100: "#DBE5E8",
  200: "#B7CBD0",
  300: "#94B0B9",
  400: "#7096A1",
  500: "#4C7C8A",
  600: "#3D636E",
  700: "#2E4A53",
  800: "#1E3237",
  900: "#0F191C",
};

const DEEP_BLUE = {
  100: "#D1D3D5",
  200: "#A4A7AB",
  300: "#767A81",
  400: "#494E57",
  500: "#1B222D",
  600: "#161B24",
  700: "#10141B",
  800: "#0B0E12",
  900: "#050709",
};

const GOLD = {
  100: "#E5D4D8",
  200: "#CBA9B1",
  300: "#B27F8B",
  400: "#985464",
  500: "#876C43",
  600: "#652131",
  700: "#4C1925",
  800: "#321018",
  900: "#19080C",
};

const ORANGE = {
  100: "#F2E6D4",
  200: "#E5CDAA",
  300: "#D9B37F",
  400: "#CC9A55",
  500: "#BF812A",
  600: "#996722",
  700: "#734D19",
  800: "#4C3411",
  900: "#261A08",
};

const BONE = {
  100: "#F8F7F5",
  200: "#F1EFEC",
  300: "#EAE8E2",
  400: "#E3E0D9",
  500: "#DCD8CF",
  600: "#B0ADA6",
  700: "#84827C",
  800: "#585653",
  900: "#2C2B29",
};

const GRAY = {
  100: "#F0F0F0",
  150: "#E1E1E1",
  200: "#D1D1D1",
  250: "#C2C2C2",
  300: "#B3B3B3",
  350: "#A4A4A4",
  400: "#949494",
  450: "#858585",
  500: "#757575",
  550: "#6A6A6A",
  600: "#5E5E5E",
  650: "#525252",
  700: "#464646",
  750: "#3B3B3B",
  800: "#2F2F2F",
  850: "#232323",
  900: "#171717",
  950: "#0C0C0C",
};

const RED = {
  100: "#FFCCCC",
  200: "#FF9999",
  300: "#FF6666",
  400: "#FF3333",
  500: "#FF0000",
  600: "#CC0000",
  700: "#990000",
  800: "#660000",
  900: "#330000",
};

const GREEN = {
  100: "#CCEBCC",
  200: "#99D699",
  300: "#66C266",
  400: "#33AD33",
  500: "#009900",
  600: "#007A00",
  700: "#005C00",
  800: "#003D00",
  900: "#001F00",
};

const BLUE = {
  100: "#CCCEFF",
  200: "#999DFF",
  300: "#666CFF",
  400: "#333BFF",
  500: "#000AFF",
  600: "#0008CC",
  700: "#000699",
  800: "#000466",
  900: "#000233",
};

declare module "@mui/material/styles" {
  interface PaletteColor {
    50?: string;
    100?: string;
    150?: string;
    200?: string;
    250?: string;
    300?: string;
    350?: string;
    400?: string;
    450?: string;
    500?: string;
    550?: string;
    600?: string;
    650?: string;
    700?: string;
    750?: string;
    800?: string;
    850?: string;
    900?: string;
    950?: string;
  }
  interface Color {
    150?: string;
    250?: string;
    350?: string;
    450?: string;
    550?: string;
    650?: string;
    750?: string;
    850?: string;
    950?: string;
  }
  interface Palette {
    gold: PaletteColor;
    orange: PaletteColor;
    bone: PaletteColor;
    neutral: PaletteColor;
    semanticNeutral: PaletteColor;
    semanticSurface: PaletteColor;
    semanticPrimary: PaletteColor;
    semanticDisabledForeground: PaletteColor;
    semanticDisabledBackground: PaletteColor;
    semanticSelected: PaletteColor;
    semanticText: PaletteColor;
  }
  interface PaletteOptions {
    gold: PaletteColorOptions;
    orange: PaletteColorOptions;
    bone: PaletteColorOptions;
    neutral: PaletteColorOptions;
    semanticNeutral: PaletteColorOptions;
    semanticSurface: PaletteColorOptions;
    semanticPrimary: PaletteColorOptions;
    semanticDisabledForeground: PaletteColorOptions;
    semanticDisabledBackground: PaletteColorOptions;
    semanticSelected: PaletteColorOptions;
    semanticText: PaletteColorOptions;
  }
}

declare module "@mui/material" {
  interface ButtonPropsColorOverrides {
    selected: true;
    common: true;
    neutral: true;
    gold: true;
    orange: true;
    bone: true;
  }
}

const common = { black: BASE[1000], white: BASE[0] };
const primary = { ...AZURE, main: AZURE[500] };
const secondary = { ...DEEP_BLUE, main: DEEP_BLUE[500], light: DEEP_BLUE[100] };
const neutral = { ...GRAY, main: GRAY[500] };
const success = { ...GREEN, main: GREEN[500] };
const error = { ...RED, main: RED[500] };
const info = { ...BLUE, main: BLUE[500] };

const palette = {
  common,
  selected: {
    main: primary.main,
    light: common.white,
    dark: primary[900],
  },
  text: {
    primary: common.black,
    secondary: neutral[500],
    main: common.black,
  },
  primary,
  secondary,
  neutral,
  success,
  error,
  info,
  gold: GOLD,
  orange: ORANGE,
  bone: BONE,
  semanticSuccess: {
    main: "#006600",
    dark: "#00AD00",
  },
  semanticPrimary: {
    main: "#8F0339",
    dark: "#5B0224",
  },
  semanticSelected: {
    main: "#2066CF",
    dark: "#4182E1",
    light: "#c9d9f3",
  },
  semanticSurface: {
    light: "#E8E8E8",
    dark: "#171717",
    main: "#FFFFFF",
  },
  semanticNeutral: {
    light: "#5D5D5D",
    main: "#747474",
    dark: "#8B8B8B",
  },
  semanticDisabledForeground: {
    main: "#B9B9B9",
    dark: "#464646",
  },
  semanticDisabledBackground: {
    light: "#E8E8E8",
    dark: "#171717",
    main: "#FFFFFF",
  },
  semanticText: {
    light: "#ffffff",
    dark: "#000000",
    main: "#ffffff",
  },
  semanticError: {
    light: "#D60000",
    dark: "#FF1A1A",
    main: "#D60000",
  },
  lightSelected: {
    main: "#DBE5E8",
    light: "#FFFFFF",
    dark: "#4C7C8A",
  },
};

export default palette;
