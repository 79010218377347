import type { WidgetValueType } from "../types.js";
import convertSecondsToTime from "./convertSecondsToTime.js";

const widgetValuesFormats: Record<string, (v: string | number) => string> = {
  TYPE_INTEGER: (val) => new Intl.NumberFormat("en-US").format(+val),
  TYPE_FLOAT: (val) => parseFloat(val as string).toFixed(2),
  TYPE_SECONDS: (val) => `${convertSecondsToTime(+val)}`,
  TYPE_STRING: (val) => (val === "N/A" ? "" : (val as string)),
};

export default function formatWidgetsValues(value: WidgetValueType): string | number {
  return value[1] in widgetValuesFormats ? widgetValuesFormats[value[1]](value[0]) : value[0];
}
