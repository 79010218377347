import { CssBaseline } from "@mui/material";
import { type ThemeOptions, ThemeProvider as MUIThemeProvider, createTheme } from "@mui/material/styles";
import type { PropsWithChildren } from "react";

import components from "./components";
import palette from "./palette";
import typography from "./typography";

export default function ThemeProvider({ children }: PropsWithChildren) {
  const themeOptions: ThemeOptions = {
    palette,
    typography,
    components,
  };

  const theme = createTheme(themeOptions);

  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MUIThemeProvider>
  );
}
