import { AssetService } from "@/services/AssetService";
import { sortMenuItems } from "@/utils/drawerUtils.js";
import {
  getDrawerOptions,
  getSortedContentTypes,
  getSortedMediaItems,
  getSortedTags,
  getVisualBuilderFilteredContentTypes,
  getVisualBuilderFilteredMediaItems,
  getVisualBuilderFilteredTags,
  getVisualBuilderMode,
  isPublicPage,
  transformContentTypesData,
  transformMediaItemData,
  transformTagsData,
} from "@/utils/visualBuilder.js";

import { visualBuilderDataSelector } from "./selectors.js";
import * as types from "./types.js";
import {
  ELEMENT_POSITIONING,
  EXPERIENCE_PRIMARY_FILTER,
  SHARE_SCOPE,
  TAB_STATUSES,
  VB_MODE,
} from "../../../constants/index.js";
import {
  ExperienceService,
  FlagService,
  PresentationService,
  PublicVisualizationService,
  VisualizationService,
} from "../../../services/index.js";
import { setSimpleValue } from "../../simpleValues/actions.js";

/**
 * Initial state for the visual builder data.
 * Contains default values for filters, drawer states, views, and other UI configurations.
 */
const visualBuilderDataInitialState = {
  // Filter-related defaults
  primaryFilter: EXPERIENCE_PRIMARY_FILTER.TAG,
  typeFilterId: null,
  tagFilterId: null,

  // Drawer visibility settings - note special handling for shared URLs
  leftDrawer: true,
  leftDrawerAutoHide: window.location.search.includes("shareId=") ? true : false,
  rightDrawer: true,
  rightDrawerAutoHide: false,
  gridView: false,

  // UI state configurations
  tagsToggled: {},
  carousel: false,
  typeTagFilter: false,
  searchField: false,
  currentMediaItemId: null,

  // Data containers
  search: {},
  contentTypes: [],
  filteredContentTypes: [],
  tags: [],
  filteredTags: [],
  mediaItems: [],
  filteredMediaItems: [],

  // Relationship mappings
  contentTypeTags: {},
  tagContentTypes: {},
  validContentTypes: {},
  validTags: {},
  tagMediaItems: {},
  filteredTagMediaItems: {},

  // State flags and ordering
  searchPerformed: false,
  contentsOrdered: {},
  experienceContentTypeIds: {},
  experienceTagIds: {},
  contentTypesOrdered: {},
  tagsOrdered: {},
  hiddenContentTypes: {},
  hiddenTags: {},
  locked: false,
  shouldToggleTagItem: true,

  // Asset-related state
  assetCategories: [],
  assetCategoriesOrdered: {},
  assetCategoriesToggled: {},
  assetCategoryContentTypes: {},
  assetCategoryFilterId: null,
  assetCategoryMediaItems: {},
  experienceAssetCategoryIds: {},
  filteredAssetCategories: [],
  filteredAssetCategoryMediaItems: {},
  hiddenAssetCategories: {},
  typesToggled: {},
};

/**
 * Action creator to set initial values for presentation mode
 * @param {Object} params - Configuration parameters including tabStatus
 * @returns {Function} Thunk action function
 */
function addPresentationInitialValues(params) {
  return async (dispatch, getState) => {
    const { tabStatus, propertyMenuItems } = params;
    const { mode } = getVisualBuilderMode(params);
    const { visualBuilderData } = visualBuilderDataSelector(params)(getState());

    const { mediaItems, contentTypes, tags, filteredAssetCategories } = visualBuilderData;

    const sortedAssetCategories = sortMenuItems({
      parentId: 0,
      itemType: "asset-category",
      items: filteredAssetCategories,
      menuItems: propertyMenuItems,
    });

    const initialCategory = sortedAssetCategories[0];

    const sortedContentTypes =
      sortMenuItems({
        parentId: initialCategory.id,
        itemType: "content-type",
        items: contentTypes,
        menuItems: propertyMenuItems,
      }) || [];

    const initialContentTypes = sortedContentTypes.filter((contentType) =>
      contentType.assetCategories.includes(initialCategory.id),
    );

    const initialContentType = initialContentTypes[0];

    const sortedMediaItems = sortMenuItems({
      parentId: initialContentType.id,
      itemType: "media-item",
      items: mediaItems,
      menuItems: propertyMenuItems,
    });

    const initialMediaItems = sortedMediaItems.filter((mediaItem) => {
      const { contentTypeId = -1 } = mediaItem;

      return contentTypeId === initialContentType.id;
    });

    const initialMediaItem = initialMediaItems[0];

    // Only proceed if in presentation mode
    if (mode !== VB_MODE.PRESENTATION) {
      return;
    }

    // Set initial values for homebase and collect tabs if media items exist
    if ([TAB_STATUSES.HOMEBASE, TAB_STATUSES.COLLECT].includes(tabStatus) && mediaItems.length) {
      const values = { gridView: false };

      if (initialContentType) {
        values.typeFilterId = initialContentType.id;
      }

      if (values.typeFilterId) {
        const initialTag = tags.find((tag) => tag.contentTypes.includes(values.typeFilterId) && !tag.hidden);
        if (initialTag) {
          values.tagFilterId = initialTag.id;
        }
      }

      // Set initial asset category
      if (values.typeFilterId) {
        if (initialCategory) {
          values.assetCategoryFilterId = initialCategory.id;
        }
      }

      // Set initial media item based on selected filters
      if (values.typeFilterId && values.tagFilterId) {
        if (initialMediaItem) {
          values.currentMediaItemId = initialMediaItem.id;
        }
      }

      dispatch(updateVisualBuilderData(params, values, {}, true));
    }
  };
}

/**
 * Action creator to set initial values for visualization mode
 * @param {Object} params - Configuration parameters
 * @returns {Function} Thunk action function
 */
const addVisualizationInitialValues = (params) => {
  return async (dispatch, getState) => {
    const { tabStatus } = params;
    const { mode } = getVisualBuilderMode(params);
    const { visualBuilderData } = visualBuilderDataSelector(params)(getState());

    const { filteredMediaItems, gridView } = visualBuilderData;

    // Only proceed for visualization mode
    if (mode !== VB_MODE.VISUALIZATION) {
      return;
    }

    // Set initial media item for customize tab
    if ([TAB_STATUSES.CUSTOMIZE].includes(tabStatus) && filteredMediaItems.length && !gridView) {
      const values = {};
      const initialMediaItem = filteredMediaItems[0];

      if (initialMediaItem) {
        values.currentMediaItemId = initialMediaItem.id;
      }

      if (Object.keys(values).length) {
        dispatch(updateVisualBuilderData(params, values));
      }
    }
  };
};

/**
 * Action creator to set initial values for public visualization
 * @param {Object} params - Configuration parameters
 * @returns {Function} Thunk action function
 */
function addPublicVisualizationInitialValues(params) {
  return async (dispatch, getState) => {
    const { tabStatus } = params;
    const { mode } = getVisualBuilderMode(params);
    const { visualBuilderData } = visualBuilderDataSelector(params)(getState());

    const {
      currentMediaItemId,
      filteredMediaItems,
      gridView,
      visibleContentTypes,
      mediaItems,
      scope,
      visibleAssetCategories,
      flags,
    } = visualBuilderData;

    // Check if we're in a public visualization mode
    if (
      ![
        VB_MODE.PUBLIC_VISUALIZATION,
        VB_MODE.PUBLIC_MEDIA_SHARE_VISUALIZATION,
        VB_MODE.PUBLIC_SINGLE_SHARE_VISUALIZATION,
      ].includes(mode)
    ) {
      return;
    }

    // Set initial drawer options and values
    const values = {
      ...getDrawerOptions({ tabStatus, visibleContentTypes, mediaItems, scope, visibleAssetCategories, flags }),
    };

    // Set initial media item for public tab
    if ([TAB_STATUSES.PUBLIC].includes(tabStatus) && filteredMediaItems.length && !gridView && !currentMediaItemId) {
      const initialMediaItem = filteredMediaItems[0];

      if (initialMediaItem) {
        values.currentMediaItemId = initialMediaItem.id;
      }
    }

    if (Object.keys(values).length) {
      dispatch(updateVisualBuilderData(params, values));
    }
  };
}

/**
 * Primary action creator to get and initialize visual builder data
 * @param {Object} params - Configuration parameters
 * @param {Object} values - Initial values to set
 * @returns {Function} Thunk action function
 */
export const getVisualBuilderData = (params, values = {}) => {
  return async (dispatch, getState) => {
    const { propertyId, experienceId, tabStatus, prevTabStatus, shareId, mediaItemId, propertyMenuItems } = params;
    const { key, mode } = getVisualBuilderMode(params);

    const query = {};
    let initial = false;

    // Determine which services to use based on mode
    let promises = [];
    if (mode === VB_MODE.PRESENTATION) {
      promises = [
        PresentationService.getContentTypes(propertyId, query),
        PresentationService.getTags(propertyId, query),
        PresentationService.getMediaItems(propertyId, query),
        AssetService.getAssetCategories(propertyId),
        FlagService.getFlags(),
      ];
    }

    if (mode === VB_MODE.VISUALIZATION) {
      promises = [
        VisualizationService.getContentTypes(propertyId, experienceId, query),
        VisualizationService.getTags(propertyId, experienceId, query),
        VisualizationService.getMediaItems(propertyId, experienceId, query),
        AssetService.getAssetCategories(propertyId),
        FlagService.getFlags(),
      ];
    }

    if (
      [
        VB_MODE.PUBLIC_VISUALIZATION,
        VB_MODE.PUBLIC_MEDIA_SHARE_VISUALIZATION,
        VB_MODE.PUBLIC_SINGLE_SHARE_VISUALIZATION,
      ].includes(mode)
    ) {
      promises = [
        PublicVisualizationService.getContentTypes(propertyId, experienceId, query, mediaItemId),
        PublicVisualizationService.getTags(propertyId, experienceId, query, mediaItemId),
        PublicVisualizationService.getMediaItems(propertyId, experienceId, query, mediaItemId),
        AssetService.getAssetCategories(propertyId),
        PublicVisualizationService.getFlags(),
      ];
    }

    // Fetch all required data
    const [
      {
        resp: { data: contentTypes },
      },
      {
        resp: { data: tags },
      },
      {
        resp: { data: mediaItems },
      },
      {
        resp: { data: assetCategories },
      },
      {
        resp: { data: flags },
      },
    ] = await Promise.all(promises);

    const { visualBuilderData } = visualBuilderDataSelector(params)(getState());

    // Build initial payload
    let payload = {
      ...(!visualBuilderData.id && { ...visualBuilderDataInitialState }),
      key,
      mode,
      tabStatus,
      ...visualBuilderData,
      ...values,
    };

    // Handle tab status changes
    if (tabStatus === TAB_STATUSES.CUSTOMIZE && prevTabStatus === TAB_STATUSES.COLLECT && payload.tabChanged) {
      const { visualBuilderData } = visualBuilderDataSelector({ ...params, tabStatus: TAB_STATUSES.COLLECT })(
        getState(),
      );
      payload.gridView = visualBuilderData.gridView;
    }

    // Handle drawer state for shared views
    if (VB_MODE.PUBLIC_SINGLE_SHARE_VISUALIZATION === mode) {
      payload.leftDrawer = false;
      payload.leftDrawerAutoHide = true;
    }

    // Process and transform data
    payload.flags = flags.reduce((prev, val) => ({ ...prev, [val.name]: val.value }), {});
    payload.contentTypes = contentTypes;
    payload.tags = tags;
    payload.mediaItems = mediaItems;
    payload.assetCategories = assetCategories;

    // Handle public media share visualization
    if ([VB_MODE.PUBLIC_MEDIA_SHARE_VISUALIZATION, VB_MODE.PUBLIC_SINGLE_SHARE_VISUALIZATION].includes(mode)) {
      const { resp: share } = await PublicVisualizationService.getPublicMediaShare(propertyId, shareId);

      if (share) {
        payload.scope = share.options.scope;
        payload.currentMediaItemId = share.options.currentMediaItemId;
        payload.primaryFilter = share.options.primaryFilter;
        payload.typeFilterId = share.options.typeFilterId;
        payload.tagFilterId = share.options.tagFilterId;
        payload.createdBy = share.createdBy;
        payload.currentShareId = share.id;
      }
    }

    // Ensure otherTag is included
    if (!payload.tags.find((tag) => tag.id === payload.otherTag.id)) {
      payload.tags.push(payload.otherTag);
    }

    // Handle single media item scope
    if (
      isPublicPage(payload.tabStatus) &&
      payload.scope &&
      payload.scope === SHARE_SCOPE.MEDIA_ITEM &&
      payload.mediaItems.length
    ) {
      let initialMediaItem;

      if (payload.currentMediaItemId) {
        initialMediaItem = payload.mediaItems.find((mediaItem) => mediaItem.id === payload.currentMediaItemId);
      }

      if (!initialMediaItem) {
        initialMediaItem = payload.mediaItems[0];
      }

      payload.gridView = false;

      if (initialMediaItem) {
        payload.mediaItems = [initialMediaItem];
      }
    }

    // Transform and sort data
    payload = transformContentTypesData(payload);
    payload = transformTagsData(payload);
    payload = transformMediaItemData(payload);
    payload = getSortedContentTypes(payload);
    payload = getSortedTags(payload);
    payload = getSortedMediaItems(payload);

    payload.propertyMenuItems = propertyMenuItems;

    // Apply filters
    payload = getVisualBuilderFilteredMediaItems(payload);
    payload = getVisualBuilderFilteredContentTypes(payload);
    payload = getVisualBuilderFilteredTags(payload);

    // Initialize if new
    if (!payload.id) {
      payload.id = crypto.randomUUID();
      payload.initialized = true;
      initial = true;
    }

    payload.rightDrawer = false;
    payload.rightDrawerAutoHide = true;

    // Dispatch update action
    dispatch({
      type: types.GET_VISUAL_BUILDER_DATA,
      payload,
    });

    // Handle initial values based on mode
    if (initial) {
      if (mode === VB_MODE.PRESENTATION) {
        dispatch(addPresentationInitialValues(params));
      }
      if (mode === VB_MODE.VISUALIZATION) {
        dispatch(addVisualizationInitialValues(params));
      }
    }

    // Handle public visualization modes
    if (
      [
        VB_MODE.PUBLIC_VISUALIZATION,
        VB_MODE.PUBLIC_MEDIA_SHARE_VISUALIZATION,
        VB_MODE.PUBLIC_SINGLE_SHARE_VISUALIZATION,
      ].includes(mode)
    ) {
      dispatch(addPublicVisualizationInitialValues(params));
    }
  };
};

/**
 * Action creator to refresh visual builder data
 * @param {Object} params - Configuration parameters
 * @returns {Function} Thunk action function
 */
function refreshVisualBuilderData(params) {
  return async (dispatch, getState) => {
    const { key, mode } = getVisualBuilderMode(params);
    const { visualBuilderData } = visualBuilderDataSelector(params)(getState());

    // Create payload with current state
    let payload = {
      key,
      mode,
      ...visualBuilderData,
    };

    // Re-apply sorting and filtering
    payload = getSortedContentTypes(payload);
    payload = getSortedTags(payload);
    payload = getSortedMediaItems(payload);

    payload = getVisualBuilderFilteredMediaItems(payload);
    payload = getVisualBuilderFilteredContentTypes(payload);
    payload = getVisualBuilderFilteredTags(payload);

    // Update state
    dispatch({
      type: types.UPDATE_VISUAL_BUILDER_DATA,
      payload: { key, ...payload },
    });

    // Handle public visualization modes
    if (
      [
        VB_MODE.PUBLIC_VISUALIZATION,
        VB_MODE.PUBLIC_MEDIA_SHARE_VISUALIZATION,
        VB_MODE.PUBLIC_SINGLE_SHARE_VISUALIZATION,
      ].includes(mode)
    ) {
      dispatch(addPublicVisualizationInitialValues(params));
    }
  };
}

/**
 * Action creator to update visual builder data
 * @param {Object} params - Configuration parameters
 * @param {Object} values - Values to update
 * @param {Object} options - Additional options
 * @param {boolean} refresh - Whether to refresh data after update
 * @returns {Function} Thunk action function
 */
export const updateVisualBuilderData = (params, values, options = {}, refresh = false) => {
  return async (dispatch, getState) => {
    const { key, mode } = getVisualBuilderMode(params);

    // Update visual builder state
    dispatch({
      type: types.UPDATE_VISUAL_BUILDER_DATA,
      payload: { key, ...values, ...options },
    });

    // Update experience if in visualization mode
    if (mode === VB_MODE.VISUALIZATION && Object.keys(values).length) {
      await ExperienceService.updateExperience(params.propertyId, params.experienceId, values);
    }

    // Handle voice memo playback state
    const voiceMemoPlayback = getState().simpleValues?.["voice-memo-playback"];

    if (
      values.currentMediaItemId &&
      voiceMemoPlayback?.element?.positioning === ELEMENT_POSITIONING.CONTENT &&
      voiceMemoPlayback?.element?.mediaItemId !== values.currentMediaItemId &&
      voiceMemoPlayback?.open
    ) {
      // Close voice memo playback when switching content
      dispatch(setSimpleValue("voice-memo-playback", { open: false }));
    }

    // Refresh data if requested
    if (refresh) {
      dispatch(refreshVisualBuilderData(params));
    }
  };
};

/**
 * Action creator to reset visual builder data
 * @param {Object} params - Configuration parameters
 * @returns {Function} Thunk action function
 */
export const resetVisualBuilderData = (params) => {
  return async (dispatch) => {
    const { key } = getVisualBuilderMode(params);

    // Reset visual builder state
    dispatch({
      type: types.RESET_VISUAL_BUILDER_DATA,
      payload: { key },
    });
  };
};
