import type { User } from "@auth0/auth0-react";
import { matchSorter } from "match-sorter";

import { EXPERIENCE_CATEGORY, EXPERIENCE_FILTER_STATUS, EXPERIENCE_STATUS } from "@/constants/experience";
import store from "@/store";

import type { Experience, ExperienceFilters } from "../types";

type StatusMatcher = {
  condition: (experience: Experience) => boolean;
  filterKey: (typeof EXPERIENCE_FILTER_STATUS)[keyof typeof EXPERIENCE_FILTER_STATUS];
};

const STATUS_MATCHERS: StatusMatcher[] = [
  {
    filterKey: EXPERIENCE_FILTER_STATUS.DRAFT,
    condition: (experience) => experience.status === EXPERIENCE_FILTER_STATUS.DRAFT,
  },
  {
    filterKey: EXPERIENCE_FILTER_STATUS.SHARED,
    condition: (experience) => experience.status === EXPERIENCE_FILTER_STATUS.SHARED,
  },
  {
    filterKey: EXPERIENCE_FILTER_STATUS.LOCKED,
    condition: (experience) => experience.locked,
  },
  {
    filterKey: EXPERIENCE_FILTER_STATUS.UNLOCKED,
    condition: (experience) => !experience.locked,
  },
];

/**
 * Gets the full collection list, which is a list to the homebase of each linked property, as "fake" experiences.
 */
function getFullCollectionList() {
  const data: Experience[] = [];
  const linkedProperties = store.getState().organizationLinkedProperties?.properties || [];

  for (const property of linkedProperties) {
    data.push({
      favorites: [],
      fullCollection: true,
      id: property?.id,
      property: property,
      propertyId: property?.id,
      title: `${property?.name} Full collection`,
      experienceMembers: [],
    } as Partial<Experience> as Experience);
  }

  return data;
}

/**
 * Filters the list of experiences based on the parameters.
 */
export function filterExperiences(
  experiences: Experience[],
  experiencesCategory: EXPERIENCE_CATEGORY,
  filters: Partial<ExperienceFilters>,
  searchText: string,
  user?: User,
) {
  const { statuses = {} } = filters || {};

  let data = experiences;

  const enableStatusFiltering =
    (experiencesCategory === EXPERIENCE_CATEGORY.MY_EXPERIENCES &&
      experiences.filter((experience) => Number(experience.createdById) === user?.id).length > 1) ||
    experiencesCategory !== EXPERIENCE_CATEGORY.MY_EXPERIENCES;

  if (experiencesCategory === EXPERIENCE_CATEGORY.PROPERTY_FULL_COLLECTIONS) {
    // organization level only
    data = getFullCollectionList();
  }

  if (isFilterPropertyValid(statuses) && enableStatusFiltering) {
    data = data.filter((experience) => doStatusMatch(experience, statuses));
  }

  if (searchText) {
    data = matchSorter(data, searchText, { keys: ["title"] });
  }

  if (user) {
    data = data.filter((experience) => doCategoryMatch(experience, experiencesCategory, user.id));
  }

  return data;
}

function doCategoryMatch(
  experience: Experience,
  category: (typeof EXPERIENCE_CATEGORY)[keyof typeof EXPERIENCE_CATEGORY],
  userId: number,
) {
  if (category === EXPERIENCE_CATEGORY.MY_EXPERIENCES) {
    return Number(experience.createdById) === userId && experience.status !== EXPERIENCE_STATUS.TEMPLATE;
  }

  // All experience tab should show all experiences except templates
  if (category === EXPERIENCE_CATEGORY.ALL_EXPERIENCES) {
    return experience.status !== EXPERIENCE_STATUS.TEMPLATE;
  }

  if (category === EXPERIENCE_CATEGORY.TEMPLATES) {
    return experience.status === EXPERIENCE_STATUS.TEMPLATE;
  }

  return true;
}

/**
 * Checks if a filter property is valid.
 * A filter property will be valid when it contains at least one key.
 */
function isFilterPropertyValid(property?: Record<string | number, boolean>) {
  return Object.keys(property || {}).length > 0;
}

/**
 * Identifies if the experience matches the types filter.
 */
function doStatusMatch(
  experience: Experience,
  statuses: Partial<Record<(typeof EXPERIENCE_FILTER_STATUS)[keyof typeof EXPERIENCE_FILTER_STATUS], boolean>>,
): boolean {
  return STATUS_MATCHERS.some((matcher) => statuses[matcher.filterKey] && matcher.condition(experience));
}
