import { createSelector } from "@reduxjs/toolkit";

import type { MenuItem } from "@/VisualBuilder/components/LeftHandMenu/types";
import type { REQUEST_STATUSES } from "@/constants";

import type { User } from "../currentUser/selectors";

export interface Property {
  id: number;
  name: string;
  status: string;
  tier: {
    title: string;
  };
  propertyMenuItems: MenuItem[];
  address?: string;
  logoFavicon?: string;
  previewImageUrl?: string;
  splashPageLogo?: string;
  splashPageDuration?: number;
  splashPageBackgroundColor?: string;
  themeColor?: string;
  settings: JSON;
  email?: string;
  website?: string;
  description?: string;
  photo?: string;
  phone: {
    id: string;
    iso: string;
    code: string;
    number: string;
  };
  features?: {
    id: number;
    name: string;
  }[];
  segments?: {
    id: number;
    name: string;
  }[];
  socialMedia?: {
    id: number;
    name: string;
    url: string;
  }[];
  customSettings: {
    experienceAvatars: boolean;
  };
  allFeatures: {
    id: number;
    name: string;
  }[];
  allSegments: {
    id: number;
    name: string;
  }[];
  allSocialMedia: {
    id: number;
    name: string;
  }[];
  numOfMembers: number;
  users: Partial<User>[];
}

interface CurrentProperty {
  property: Property;
  status: REQUEST_STATUSES;
}

interface CurrentPropertyState {
  currentProperty: CurrentProperty;
}

export const currentPropertySelector = createSelector(
  (state: CurrentPropertyState) => state.currentProperty,
  (currentProperty) => {
    return {
      ...currentProperty,
    };
  },
);
