import type { PropertyOrganizationValues } from "@/Dashboard/pages/CreateProperty/types";
import { REQUEST_STATUSES } from "@/constants/index.js";

import * as types from "./types.js";

interface ActionPayload {
  propertyOrganizationValues?: PropertyOrganizationValues;
  status?: string;
  err?: unknown;
}

interface Action {
  type: string;
  payload: ActionPayload;
}

const initialState = {
  propertyOrganizationValues: {
    brandingParents: [],
    brands: [],
    management: [],
    ownership: [],
  } as PropertyOrganizationValues,
  status: REQUEST_STATUSES.IDLE,
  err: null,
};

export function propertyOrganizationValuesReducer(state = initialState, action: Action) {
  if (action.type === types.GET_PROPERTY_ORGANIZATION_VALUES) {
    const { status, err, propertyOrganizationValues } = action.payload;
    return {
      ...state,
      propertyOrganizationValues,
      status,
      err,
    };
  }

  if (action.type === types.SET_PROPERTY_ORGANIZATION_VALUES_REQUEST_STATUS) {
    return {
      ...state,
      status: action.payload.status,
    };
  }

  return state;
}
