export const ENTITY = {
  PROPERTY: "property",
  USER: "user",
  MEMBER: "member",
  MEDIA_ITEM: "media_item",
  EXPERIENCE: "experience",
  CONTACT: "contact",
  TAG: "tag",
  UNIV_CATEGORY: "univ_category",
  UNIV_VIDEO: "univ_video",
  SHARE: "share",
  PHONE: "phone",
  NOTIFICATION: "notification",
  MEDIA_ITEM_TAG: "media_item_tag",
  MEDIA_ITEM_MEMBER: "media_item_member",
  ICON: "icon",
  HOTSPOT: "hotspot",
  EXPERIENCE_ASSET_CATEGORY: "experience_asset_category",
  EXPERIENCE_CONTACT: "experience_contact",
  EXPERIENCE_CONTENT_TYPE: "experience_content_type",
  EXPERIENCE_MEDIA_ITEM: "experience_media_item",
  EXPERIENCE_MEMBER: "experience_member",
  EXPERIENCE_TAG: "experience_tag",
  ELEMENT: "element",
  CONTENT_ELEMENT: "content_element",
  CONTENT_TYPE: "content_type",
  CONTACT_NOTE: "contact_note",
  FAVORITE: "favorite",
  VIEW: "view",
  FLAG: "flag",
  ASSET_CATEGORY: "asset_category",
  ORGANIZATIONS: "organizations",
  ORGANIZATION: "organization",
} as const;

export type ENTITY = (typeof ENTITY)[keyof typeof ENTITY];
