import type { LoaderFunctionArgs } from "react-router-dom";

import { DEFAULT_LIMIT, DEFAULT_OFFSET, REQUEST_STATUSES, SORT_FIELD, SORT_ORIENTATION } from "@/constants";
import { ExperienceService, MemberService, PropertyService } from "@/services";
import store from "@/store";
import { getFlags } from "@/store/actions";
import type { Flags } from "@/store/types";
import { GET_CURRENT_PROPERTY } from "@/store/v2/currentProperty/types";
import { SET_MEMBERS_REQUEST_STATUS } from "@/store/v2/members/types";
import { waitForAuthReady } from "@/utils/waitForAuthReady";

export async function experiencesLoader({ params }: LoaderFunctionArgs) {
  return waitForAuthReady(async () => {
    const propertyId = params.propertyId;

    if (!propertyId) {
      throw new Response("Not Found", { status: 404 });
    }

    // TODO: This is a temporary solution to check if the feature flag is enabled, need  to remove this once the flag is enabled for all users
    let state = store.getState();
    const hasFlags = (state.flags as { flags: Flags })?.flags;

    if (!hasFlags) {
      await store.dispatch(getFlags());
      state = store.getState();
    }

    const useV2 = (state.flags as { flags: Flags })?.flags?.["user-collection"];

    if (!useV2) {
      return null;
    }

    const membersQuery = {
      sortField: SORT_FIELD.MEMBER_ROLE,
      sortOrientation: SORT_ORIENTATION.ASC,
      offset: DEFAULT_OFFSET,
      limit: DEFAULT_LIMIT,
    };

    const experiencesQuery = {
      sortField: SORT_FIELD.EXPERIENCE_CREATED_AT,
      sortOrientation: SORT_ORIENTATION.DESC,
      offset: DEFAULT_OFFSET,
      limit: 1000,
      organizationId: null,
    };

    const membersRequest = MemberService.getMembers(+propertyId, membersQuery);

    const experiencesRequest = ExperienceService.getExperiences(+propertyId, experiencesQuery);

    const propertyRequest = PropertyService.getProperty(propertyId);

    store.dispatch({
      type: SET_MEMBERS_REQUEST_STATUS,
      payload: { propertyId: +propertyId, status: REQUEST_STATUSES.LOADING },
    });

    const dispatchExperiencesData = async () => {
      const responses = await Promise.allSettled([membersRequest, experiencesRequest, propertyRequest]);

      const [membersResult, experiencesResult, propertyResult] = responses;

      if (membersResult.status === "fulfilled") {
        store.dispatch({
          type: "GET_MEMBERS",
          payload: {
            propertyId: +propertyId,
            members: membersResult.value.resp?.data || [],
            status: membersResult.value.status,
            err: membersResult.value.err,
            query: membersQuery,
          },
        });
      }

      if (experiencesResult.status === "fulfilled") {
        store.dispatch({
          type: "experiencesV2/fetchExperiences/fulfilled",
          payload: {
            propertyId: +propertyId,
            experiences: experiencesResult.value.resp.data,
            count: experiencesResult.value.resp.count,
            status: experiencesResult.value.status,
            err: experiencesResult.value.err,
          },
        });
      }

      if (propertyResult.status === "fulfilled") {
        const property = propertyResult.value.resp;
        store.dispatch({
          type: GET_CURRENT_PROPERTY,
          payload: {
            property: property ? { ...property, otherTag: property.tags?.[0] } : {},
            status: propertyResult.value.status,
            err: propertyResult.value.err,
          },
        });
      }
    };

    return {
      dispatchExperiencesData,
    };
  });
}
