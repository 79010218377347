import { type PayloadAction, createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";

import { REQUEST_STATUSES } from "@/constants/requestStatuses";
import { OrganizationsService } from "@/services/OrganizationsService";
import type { State } from "@/store/types";

import type { CurrentOrganizationState, Organization } from "./types";

const initialState: CurrentOrganizationState = {
  data: null,
  status: REQUEST_STATUSES.IDLE,
  err: null,
  noResults: false,
};

interface SetCurrentOrganizationPayload {
  data: Organization | null;
  status: REQUEST_STATUSES;
  err: unknown;
  noResults: boolean;
}

export const fetchCurrentOrganization = createAsyncThunk(
  "currentOrganization/fetchCurrentOrganization",
  async (organizationId: number) => {
    const { resp, status, err } = await OrganizationsService.getOrganizationById(organizationId);
    return {
      data: resp || null,
      status,
      err,
      noResults: !resp,
    } as SetCurrentOrganizationPayload;
  },
);

const currentOrganizationSlice = createSlice({
  name: "currentOrganization",
  initialState,
  reducers: {
    resetCurrentOrganization: () => initialState,
    setCurrentOrganizationData: (state, action: PayloadAction<SetCurrentOrganizationPayload>) => {
      state.data = action.payload.data;
      state.status = action.payload.status;
      state.err = action.payload.err;
      state.noResults = action.payload.noResults;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrentOrganization.pending, (state) => {
        state.status = REQUEST_STATUSES.LOADING;
        state.err = null;
        state.noResults = false;
      })
      .addCase(fetchCurrentOrganization.fulfilled, (state, action: PayloadAction<SetCurrentOrganizationPayload>) => {
        state.data = action.payload.data;
        state.status = action.payload.status;
        state.err = action.payload.err;
        state.noResults = action.payload.noResults;
      })
      .addCase(fetchCurrentOrganization.rejected, (state, action) => {
        state.status = REQUEST_STATUSES.FAILED;
        state.err = action.error;
        state.noResults = false;
      });
  },
});

export const { setCurrentOrganizationData } = currentOrganizationSlice.actions;

export default currentOrganizationSlice.reducer;

export const currentOrganizationSelector = createSelector(
  (state: State) => state.currentOrganization,
  (currentOrganization) => ({
    ...initialState,
    ...currentOrganization,
  }),
);
