import { REQUEST_STATUSES } from "@/constants/index.js";

import * as types from "./types.js";
import { getNewList } from "../../../utils/helper.js";

const initialState = {};

const experienceInitialState = {
  experiences: [],
  status: REQUEST_STATUSES.IDLE,
  err: null,
  noResults: false,
};

export const recentExperiencesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_RECENT_EXPERIENCES: {
      const { propertyId, experiences, status, err, query } = action.payload;

      const newExperiences = getNewList(query, state[propertyId].experiences || [], experiences);
      return {
        ...state,
        [propertyId]: {
          ...state[propertyId],
          experiences: newExperiences,
          status,
          err,
          noResults: !newExperiences.length,
        },
      };
    }
    case types.SET_RECENT_EXPERIENCES_REQUEST_STATUS: {
      const { propertyId } = action.payload;
      return {
        ...state,
        [propertyId]: { ...state[propertyId], ...action.payload },
      };
    }
    case types.RESET_RECENT_EXPERIENCES: {
      const { propertyId } = action.payload;

      if (propertyId) {
        return { ...state, [propertyId]: { ...experienceInitialState } };
      }
      return { ...initialState };
    }
    default:
      return state;
  }
};
