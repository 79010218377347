import { SvgIcon, type SvgIconProps } from "@mui/material";

export default function Error(props: SvgIconProps) {
  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <line x1="10" y1="5" x2="10" y2="10" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <circle cx="10" cy="14" r="1" fill="currentColor" stroke="currentColor" />
      <circle cx="10" cy="10" r="9" stroke="currentColor" fill="none" strokeWidth="1.5" />
    </SvgIcon>
  );
}
