import { type PayloadAction, createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";

import type { Experience, ExperienceFilters } from "@/Dashboard/pages/ExperiencesV2/types";
import { filterExperiences } from "@/Dashboard/pages/ExperiencesV2/utils/experienceFilters";
import { sortExperiences } from "@/Dashboard/pages/ExperiencesV2/utils/experienceSort";
import { REQUEST_STATUSES } from "@/constants";
import type { EXPERIENCE_CATEGORY } from "@/constants/experience";
import { OrganizationsService } from "@/services/OrganizationsService";
import type { selectUser } from "@/store/auth/auth.slice";
import type { State } from "@/store/types";

export interface OrganizationExperiencesState {
  [organizationId: number]: typeof defaultState;
}

const defaultState = {
  data: [] as Experience[],
  status: REQUEST_STATUSES.IDLE as REQUEST_STATUSES,
  err: null as unknown,
  noResults: false as boolean,
} as const;

const initialState: OrganizationExperiencesState = {};

export const fetchOrganizationExperiences = createAsyncThunk(
  "organizationExperiences/fetchOrganizationExperiences",
  async ({ organizationId, query }: { organizationId: number; query?: unknown }) => {
    const { resp, status, err } = await OrganizationsService.getExperiences(organizationId, query);
    const { data: experiences = [], count = 0 } = resp || {};

    return {
      organizationId,
      experiences,
      count,
      status,
      err,
      query,
    };
  },
);

const organizationExperiencesSlice = createSlice({
  name: "organizationExperiences",
  initialState,
  reducers: {
    updateExperience: (state, action: PayloadAction<{ organizationId: number; experience: Experience }>) => {
      const { organizationId, experience } = action.payload;
      const data = state[organizationId]?.data || [];
      const index = data.findIndex((x) => x.id === experience.id);
      if (index !== -1) {
        data[index] = experience;
        state[organizationId].data = [...data];
      }
    },

    deleteExperiences: (state, action: PayloadAction<{ organizationId: number; experienceIds: number[] }>) => {
      const { organizationId, experienceIds } = action.payload;
      if (state[organizationId]) {
        state[organizationId].data = state[organizationId].data.filter(
          (experience) => !experienceIds.includes(experience.id),
        );
      }
    },

    resetExperiences: (state, action: PayloadAction<{ organizationId: number }>) => {
      const { organizationId } = action.payload;
      delete state[organizationId];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrganizationExperiences.pending, (state, action) => {
        const organizationId = action.meta.arg.organizationId;
        if (!state[organizationId]) {
          state[organizationId] = { ...defaultState };
        }
        state[organizationId].status = REQUEST_STATUSES.LOADING;
        state[organizationId].err = null;
      })
      .addCase(fetchOrganizationExperiences.fulfilled, (state, action) => {
        const { organizationId, experiences, status, err } = action.payload;
        state[organizationId] = {
          ...state[organizationId],
          data: experiences,
          status,
          err,
          noResults: experiences.length === 0,
        };
      })
      .addCase(fetchOrganizationExperiences.rejected, (state, action) => {
        const organizationId = action.meta.arg.organizationId;
        if (state[organizationId]) {
          state[organizationId].status = REQUEST_STATUSES.FAILED;
          state[organizationId].err = action.error;
        }
      });
  },
});

export const organizationExperiencesSelector = (organizationId: number) =>
  createSelector(
    (state: State) => state.organizationExperiences,
    (organizationExperiences) => ({
      ...defaultState,
      ...organizationExperiences?.[organizationId],
    }),
  );

export const selectFilteredAndSortedExperiencesFromOrganization = ({
  organizationId,
  experienceCategory,
  filters,
  searchText,
  sortField,
  sortOrientation,
  user,
}: {
  organizationId: number;
  experienceCategory: EXPERIENCE_CATEGORY;
  filters: ExperienceFilters;
  searchText: string;
  sortField: string;
  sortOrientation: string;
  user: ReturnType<typeof selectUser>;
}) => {
  return (state: State) => {
    const { data = [] } = state.organizationExperiences?.[organizationId] || {};

    const filtered = filterExperiences(data, experienceCategory, filters, searchText, user);

    return sortExperiences(filtered, sortField, sortOrientation);
  };
};

export default organizationExperiencesSlice.reducer;
