import { TAB_STATUSES } from "@/constants/index.js";
import { getVisualBuilderMode } from "@/utils/visualBuilder";

export interface UrlParams {
  experienceId?: string | number;
  mediaItemId?: string | number;
  page?: string;
  propertyId?: string | number | null;
  root?: string;
  shareId?: string | null;
  tabStatus?: string;
  userId?: string | number;
  organizationId?: string | null;
}

interface ParsedParams extends UrlParams {
  mode?: string;
  vbKey?: string;
}

export default function parseParams(params: UrlParams, pathname: string): ParsedParams {
  const paths = pathname.split("/").filter((item) => !!item);
  const root = paths[0];
  const page = root === "organizations" ? paths[5] : paths[2];

  const newParams: Partial<ParsedParams> = { ...params, root, page: page || "" };

  if (newParams.propertyId === "create") {
    newParams.propertyId = null;
  }

  if (root === "properties" && newParams.propertyId) {
    if (newParams.experienceId) {
      const [, , , , tabStatus] = paths;
      newParams.tabStatus = tabStatus || TAB_STATUSES.PUBLIC;
    } else if (page === "all-assets-share") {
      newParams.tabStatus = TAB_STATUSES.PUBLIC_MEDIA_SHARE;
      newParams.shareId = new URLSearchParams(window.location.search).get("shareId");
    } else if (page === "single-asset-share") {
      newParams.tabStatus = TAB_STATUSES.PUBLIC_SINGLE_SHARE;
      newParams.shareId = new URLSearchParams(window.location.search).get("shareId");
    } else if (!page) {
      newParams.tabStatus = TAB_STATUSES.HOMEBASE;
    }
  }

  if (root === "organizations" && newParams.propertyId) {
    const [, , , , , , tabStatus] = paths;
    if (newParams.experienceId) {
      newParams.tabStatus = tabStatus || TAB_STATUSES.PUBLIC;
    } else if (!page) {
      newParams.tabStatus = TAB_STATUSES.HOMEBASE;
    }
  }

  if (root === "profile") {
    newParams.propertyId = null;
  }

  if (newParams.tabStatus) {
    const { key, mode } = getVisualBuilderMode(newParams) as {
      key: string;
      mode: string;
    };
    newParams.vbKey = key;
    newParams.mode = mode;
  }

  if (newParams.propertyId && !Number.isNaN(+newParams.propertyId)) {
    newParams.propertyId = +newParams.propertyId;
  }

  if (newParams.mediaItemId && !Number.isNaN(+newParams.mediaItemId)) {
    newParams.mediaItemId = +newParams.mediaItemId;
  }

  if (newParams.experienceId && !Number.isNaN(+newParams.experienceId)) {
    newParams.experienceId = +newParams.experienceId;
  }

  return newParams;
}
