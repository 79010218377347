import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, type Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";

import { NoPageIcon } from "../../../icons";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: 192,
    height: 192,
    display: "none",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  paper: {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TODO FIXME
    backgroundColor: theme.palette.common.white,
    height: "100%",
    margin: 0,
    width: "100%",
  },
  logo: {
    maxWidth: "100%",
  },
  noresult: {
    display: "flex",
    "justify-content": "center",
    top: "50%",
    textAlign: "center",
    "& h4": {
      fontSize: "24px",
      color: theme.palette.primary.main,
      marginBottom: "3rem",
    },
    "& .MuiButton-contained:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
    "& .MuiListItem-button:hover": {
      backgroundColor: "transparent",
    },
    "& .MuiListItemText-primary": {
      display: "block",
      padding: 0,
      color: theme.palette.primary.main,
      fontSize: "18px",
      textAlign: "center",
      margin: "0 auto",
      marginTop: "32px",
      maxWidth: "240px",
    },
    "@media (max-width:959px)": {
      left: "50%",
    },
  },
  button: {
    borderRadius: "24px",
    height: "48px",
    backgroundColor: theme.palette.primary.main,
    textAlign: "center",
    width: "350px",
    color: theme.palette.common.white,
    marginTop: "3rem",
    fontSize: "1rem",
    fontWeight: 400,
    "@media (max-width:576px)": {
      width: "300px",
    },
    textTransform: "capitalize",
  },
  logout: {
    marginTop: "1rem",
  },
}));

interface DoesNotExistProps {
  readonly message?: string;
  readonly returnPrev?: boolean;
}

export default function DoesNotExist(props: DoesNotExistProps) {
  const { message = "This page does not exist.", returnPrev = true } = props;

  const navigate = useNavigate();
  const classes = useStyles({});
  const { logout } = useAuth0();

  return (
    <div className={classes.container}>
      <div className={classes.noresult}>
        <Box width="600px">
          <h4>uh oh!</h4>
          <NoPageIcon sx={{ color: (theme: Theme) => theme.palette.primary.main, fontSize: "48px" }} />
          <Typography style={{ marginTop: "2em" }} variant="h6">
            {message}
          </Typography>
          <Box display="flex" flexDirection="column" alignItems="center">
            {returnPrev && (
              <Button
                variant="contained"
                onClick={() => {
                  navigate(-1);
                }}
                className={classes.button}
              >
                return to previous
              </Button>
            )}
            <Button variant="contained" onClick={() => void logout()} className={`${classes.button} ${classes.logout}`}>
              logout
            </Button>
          </Box>
        </Box>
      </div>
    </div>
  );
}
