import { type LoaderFunctionArgs, redirect } from "react-router-dom";

import store from "@/store";
import { waitForAuthReady } from "@/utils/waitForAuthReady";

export const visualBuilderLoader = async ({ params, request }: LoaderFunctionArgs) => {
  const shouldRedirect = request.url.includes("?redirect=true");
  const { propertyId } = params;

  return waitForAuthReady(async () => {
    const permissions = store.getState().auth?.user?.permissions;
    const canReadMediaItems = permissions?.includes("media_items:read");

    if (canReadMediaItems && shouldRedirect) {
      return redirect(`/properties/${propertyId}/media`);
    } else if (!canReadMediaItems && shouldRedirect) {
      return redirect(`/properties/${propertyId}/experiences`);
    }

    return null;
  });
};
