import { REQUEST_STATUSES } from "@/constants/index.js";

import * as types from "./types.js";
import { getNewList } from "../../../utils/helper.js";

const initialState = {
  properties: [],
  status: REQUEST_STATUSES.IDLE,
  err: null,
  noResults: false,
};

export const propertiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PROPERTIES: {
      const { properties, status, err, query } = action.payload;
      const newProperties = getNewList(query, state.properties, properties);
      return {
        ...state,
        properties: newProperties,
        status,
        err,
        noResults: !newProperties.length,
      };
    }
    case types.SET_PROPERTIES_REQUEST_STATUS:
      return { ...state, ...action.payload };
    case types.RESET_PROPERTIES:
      return { ...initialState };
    default:
      return state;
  }
};
