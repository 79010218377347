import { faChevronLeft } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, type ButtonProps, styled } from "@mui/material";

const StyledButton = styled(Button)(({ theme }) => ({
  ...theme.typography.body_semi_bold,
  textTransform: "none",
  width: "100px",
  height: "40px",
}));

interface BackButtonProps extends Omit<ButtonProps, "startIcon"> {
  readonly variant?: "text";
}

export default function BackButton({ disabled = false, onClick, variant = "text" }: BackButtonProps) {
  return (
    <StyledButton
      variant={variant}
      disabled={disabled}
      startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
      onClick={onClick}
    >
      Back
    </StyledButton>
  );
}
