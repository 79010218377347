import { REQUEST_STATUSES } from "@/constants";

import { type MediaShare } from "./selectors";
import * as types from "./types";

const initialState = {
  mediaShare: {},
  status: REQUEST_STATUSES.IDLE,
  err: null,
};

interface ActionPayload {
  mediaShare?: MediaShare;
  status?: string;
  err?: unknown;
}

interface Action {
  type: string;
  payload: ActionPayload;
}

export const currentMediaShareReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case types.GET_CURRENT_MEDIA_SHARE:
    case types.SET_CURRENT_MEDIA_SHARE_STATUS:
    case types.UPDATE_CURRENT_MEDIA_SHARE_OPENED:
    case types.UPDATE_CURRENT_MEDIA_SHARE:
      return { ...state, ...action.payload };
    case types.RESET_CURRENT_MEDIA_SHARE:
      return { ...initialState };
    default:
      return state;
  }
};
