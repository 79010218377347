import { REQUEST_STATUSES } from "@/constants/index.js";

import * as types from "./types.js";

const initialState = {
  experience: {},
  status: REQUEST_STATUSES.IDLE,
  err: null,
};

export const currentExperienceReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CURRENT_EXPERIENCE:
      return { ...state, ...action.payload };
    case types.SET_CURRENT_EXPERIENCE_REQUEST_STATUS:
      return { ...state, ...action.payload };
    case types.UPDATE_EXPERIENCE_ACTIVITY:
    case types.UPDATE_CURRENT_EXPERIENCE:
      return { ...state, ...action.payload };
    case types.RESET_CURRENT_EXPERIENCE:
      return { ...initialState };
    default:
      return state;
  }
};
