import { REQUEST_STATUSES } from "@/constants/index.js";

import * as types from "./types.js";

const initialState = {};

const elementInitialState = {
  elements: [],
  status: REQUEST_STATUSES.IDLE,
  err: null,
};

export const elementsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ELEMENTS: {
      const { propertyId, experienceId, elements, status, err } = action.payload;

      return {
        ...state,
        [`${propertyId}${experienceId}`]: {
          elements,
          status,
          err,
        },
      };
    }
    case types.REFRESH_ELEMENTS: {
      const { propertyId, experienceId, element } = action.payload;
      const key = `${propertyId}${experienceId}`;
      let { elements = [] } = state[key] || {};
      elements = elements.map((el) => (el.id === element.id ? element : el));

      return {
        ...state,
        [key]: {
          ...elementInitialState,
          ...(state[key] || {}),
          elements,
        },
      };
    }
    case types.SET_ELEMENTS_REQUEST_STATUS: {
      const { propertyId, experienceId, status } = action.payload;
      const key = `${propertyId}${experienceId}`;

      return {
        ...state,
        [key]: {
          ...elementInitialState,
          ...(state[key] || {}),
          status,
        },
      };
    }
    case types.RESET_ELEMENTS: {
      const { propertyId, experienceId } = action.payload;

      if (propertyId && experienceId) {
        return { ...state, [`${propertyId}${experienceId}`]: elementInitialState };
      }

      return state;
    }
    default:
      return state;
  }
};
