export const ACTION = {
  CREATED: "created",
  UPDATED: "updated",
  DELETED: "deleted",
  BULK_CREATED: "bulk_created",
  BULK_UPDATED: "bulk_updated",
  BULK_DELETED: "bulk_deleted",
  VIEWED: "viewed",
} as const;

export type ACTION = (typeof ACTION)[keyof typeof ACTION];

export const ACTION_NAME = {
  VIEWED: "VIEWED",
  SHARED: "SHARED",
  CREATED: "CREATED",
  CONTACTED: "CONTACTED",
  BULK_CREATED: "BULK_CREATED",
  DELETED: "DELETED",
  BULK_DELETED: "BULK_DELETED",
  ACCEPTED_INVITATION: "ACCEPTED_INVITATION",
  CREATED_ACCOUNT: "CREATED_ACCOUNT",
  GENERIC: "GENERIC",
  COLLECTED_ASSET: "COLLECTED_ASSET",
  UNCOLLECTED_ASSET: "UNCOLLECTED_ASSET",
  ADDED_ELEMENT: "ADDED_ELEMENT",
  REMOVED_ELEMENT: "REMOVED_ELEMENT",
  CHANGED_MENU_ORDER: "CHANGED_MENU_ORDER",
  CHANGED_MENU_NAME: "CHANGED_MENU_NAME",
  CHANGED_MENU_VISIBILITY: "CHANGED_MENU_VISIBILITY",
} as const;

export type ACTION_NAME = (typeof ACTION_NAME)[keyof typeof ACTION_NAME];

export const ACTION_OBJECT = {
  ASSET: "ASSET",
  PROPERTY: "PROPERTY",
  EXPERIENCE: "EXPERIENCE",
  APPLICATION: "APPLICATION",
  GENERIC: "GENERIC",
  FULL_COLLECTION: "FULL_COLLECTION",
} as const;

export type ACTION_OBJECT = (typeof ACTION_OBJECT)[keyof typeof ACTION_OBJECT];

export const ACTION_CATEGORY = {
  ASSET: "ASSET",
  ENGAGEMENT: "ENGAGEMENT",
  GENERAL: "GENERAL",
} as const;

export type ACTION_CATEGORY = (typeof ACTION_CATEGORY)[keyof typeof ACTION_CATEGORY];
