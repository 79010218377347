import { REQUEST_STATUSES } from "@/constants/index.js";

import * as types from "./types.js";
import { getNewList } from "../../../utils/helper.js";

const initialState = {
  contacts: [],
  indexes: {},
  status: REQUEST_STATUSES.IDLE,
  err: null,
};

export const contactsReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_CONTACTS: {
      const { propertyId, contacts, status, err, query } = action.payload;
      const newContacts = getNewList(query, state[propertyId].contacts, contacts);

      return {
        ...state,
        [propertyId]: {
          contacts: newContacts,
          status,
          err,
          noResults: !newContacts.length,
        },
      };
    }
    case types.SET_CONTACTS_REQUEST_STATUS: {
      const { propertyId, status } = action.payload;
      return {
        ...state,
        [propertyId]: { ...(state[propertyId] || {}), status },
      };
    }
    case types.RESET_CONTACTS: {
      const { propertyId } = action.payload;
      if (propertyId) {
        return { ...state, [propertyId]: initialState };
      }

      return {};
    }
    default:
      return state;
  }
};
