import { createSelector } from "@reduxjs/toolkit";

import type { Experience } from "@/Dashboard/pages/ExperiencesV2/types";
import { REQUEST_STATUSES } from "@/constants";

interface ExperienceState {
  experience: Partial<Experience>;
  status: REQUEST_STATUSES;
  err: Error | null;
}

const initialState: ExperienceState = {
  experience: {},
  status: REQUEST_STATUSES.IDLE,
  err: null,
};

interface CurrentExperience {
  experience: Experience;
}

interface CurrentExperienceState {
  currentExperience: CurrentExperience;
}

export const currentExperienceSelector = createSelector(
  (state: CurrentExperienceState) => state.currentExperience,
  (currentExperience) => {
    return { ...initialState, ...currentExperience };
  },
);
