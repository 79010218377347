import { api } from "./instance";
import { REQUEST_STATUSES } from "../constants";
import { getErrorResponse } from "../utils/helper";

export class ActionService {
  static async addAction(data) {
    try {
      const resp = await api({
        method: "post",
        url: `/api/v2/actions`,
        data,
      });

      return {
        resp: resp.data,
        err: null,
        status: REQUEST_STATUSES.SUCCEEDED,
      };
    } catch (err) {
      return {
        resp: null,
        err: getErrorResponse(err),
        status: REQUEST_STATUSES.FAILED,
      };
    }
  }
}
