import { ELEMENT_POPOVER_ACTION } from "@/constants/index.js";

import * as types from "./types.js";

const initialState = {
  hotspotMode: { status: false, type: null },
  mapHotspotCanvas: {
    open: false,
    elementId: null,
    url: null,
  },
  popoverAction: ELEMENT_POPOVER_ACTION.LINK,
  activeHotspot: null,
  mapPreview: null,
  hotspotReorders: 0,
  fromHotspot: false,
};

export const hotspotDataReducer = (state = {}, action) => {
  switch (action.type) {
    case types.SET_HOTSPOT_DATA: {
      const { propertyId, values } = action.payload;
      return {
        ...state,
        [propertyId]: {
          ...(state[propertyId] || {}),
          ...values,
        },
      };
    }
    case types.RESET_HOTSPOT_DATA: {
      const { propertyId } = action.payload;

      if (propertyId) {
        return {
          ...state,
          [propertyId]: initialState,
        };
      }

      return {};
    }
    default:
      return state;
  }
};
