import { REQUEST_STATUSES } from "@/constants/index.js";

import * as types from "./types.js";

const initialState = {
  contentTypes: [],
  status: REQUEST_STATUSES.IDLE,
  err: null,
};

export const contentTypesReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_CONTENT_TYPES: {
      const { propertyId, contentTypes, status, err } = action.payload;
      return {
        ...state,
        [propertyId]: {
          contentTypes,
          status,
          err,
          noResults: !contentTypes.length,
        },
      };
    }
    case types.SET_CONTENT_TYPES_REQUEST_STATUS: {
      const { propertyId, status } = action.payload;
      return {
        ...state,
        [propertyId]: { ...(state[propertyId] || {}), status },
      };
    }
    case types.RESET_CONTENT_TYPES: {
      const { propertyId } = action.payload;
      if (propertyId) {
        return { ...state, [propertyId]: initialState };
      }

      return {};
    }
    default:
      return state;
  }
};
