import type { Components } from "@mui/material";
// temp file until we transition towards web components
const components: Components = {
  MuiButton: {
    styleOverrides: {
      root: {
        fontWeight: 400,
        textTransform: "none",
      },
    },
  },
};

export default components;
