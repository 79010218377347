import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { selectUser } from "@/store/auth/auth.slice.js";
import { useThunkSelector } from "@/store/hooks.js";

import parseParams from "./parseParams.js";

export function useUrlParams() {
  const user = useThunkSelector(selectUser);
  const routerParams = useParams();
  const location = useLocation();

  const [params, setParams] = useState({
    ...parseParams({ ...routerParams, userId: user?.id }, location.pathname),
  });

  useEffect(() => {
    setParams({ ...parseParams({ ...routerParams, userId: user?.id }, location.pathname) });
  }, [user?.id, location.pathname, JSON.stringify(routerParams)]);

  return params;
}
