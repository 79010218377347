import { StyledEngineProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { RouterProvider } from "react-router-dom";

import ThemeProvider from "@/config/theme";
import router from "@/routes";

import Tracer from "./Tracer";
import OpenGraphTags from "../../VisualBuilder/components/OpenGraphTags";

export default function Root() {
  return (
    <>
      <Tracer />
      <OpenGraphTags />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider>
            <RouterProvider router={router} />
          </ThemeProvider>
        </StyledEngineProvider>
      </LocalizationProvider>
    </>
  );
}
