import { type PayloadAction, createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";

import { REQUEST_STATUSES } from "@/constants/requestStatuses";
import { OrganizationsService } from "@/services/OrganizationsService";
import type { State } from "@/store/types";
import type { Property } from "@/store/v2/currentProperty/selectors";
import type { Query } from "@/store/v2/queries/types";

interface OrganizationLinkedPropertiesState {
  properties: Property[];
  count: number;
  status: REQUEST_STATUSES;
  error: unknown;
  noResults: boolean;
}

const initialState: OrganizationLinkedPropertiesState = {
  properties: [],
  count: 0,
  status: REQUEST_STATUSES.IDLE,
  error: null,
  noResults: false,
};

export const fetchOrganizationLinkedProperties = createAsyncThunk(
  "organizationLinkedProperties/fetchOrganizationLinkedProperties",
  async ({ organizationId, query }: { organizationId: number; query: Query }, { rejectWithValue }) => {
    try {
      const { resp } = await OrganizationsService.getLinkedProperties(organizationId, query);
      return resp;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

const organizationLinkedPropertiesSlice = createSlice({
  name: "organizationLinkedProperties",
  initialState,
  reducers: {
    resetOrganizationLinkedProperties: () => initialState,
    setLinkedPropertiesReqStatus: (state, action: PayloadAction<REQUEST_STATUSES>) => {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrganizationLinkedProperties.pending, (state) => {
        state.status = REQUEST_STATUSES.LOADING;
        state.error = null;
      })
      .addCase(
        fetchOrganizationLinkedProperties.fulfilled,
        (state, action: PayloadAction<{ data: Property[]; count: number } | null>) => {
          if (action.payload) {
            state.properties = action.payload.data;
            state.count = action.payload.count;
            state.noResults = !action.payload.data.length;
          } else {
            state.properties = [];
            state.count = 0;
            state.noResults = true;
          }
          state.status = REQUEST_STATUSES.SUCCEEDED;
        },
      )
      .addCase(fetchOrganizationLinkedProperties.rejected, (state, action) => {
        state.status = REQUEST_STATUSES.FAILED;
        state.error = action.payload;
      });
  },
});

export const { setLinkedPropertiesReqStatus } = organizationLinkedPropertiesSlice.actions;
export default organizationLinkedPropertiesSlice.reducer;

export const organizationLinkedPropertiesSelector = createSelector(
  (state: State) => state.organizationLinkedProperties,
  (organizationLinkedProperties) => ({
    ...initialState,
    ...organizationLinkedProperties,
  }),
);

export type { OrganizationLinkedPropertiesState };
