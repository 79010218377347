import { FontAwesomeIcon, type FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { Button, type ButtonProps, type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

type ButtonVariant = "filled" | "outlined" | "text" | "secondary" | "contextual";

interface LabeledIconButtonProps {
  icon?: FontAwesomeIconProps["icon"];
  label: string;
  onClick?: () => void;
  btnVariant: ButtonVariant;
  iconFontSize?: string;
}

interface LabeledIconButtonPropsStyled {
  disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  common: {
    ...theme.typography.body,
    borderRadius: "24px",
    textTransform: "none",
    padding: "8px 16px",
    height: "40px",
    gap: "8px",
    "& .MuiTouchRipple-root": {
      opacity: 0,
    },
    "& .MuiButton-startIcon": {
      margin: 0,
      width: "24px",
      height: "24px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  filled: {
    backgroundColor: (props: LabeledIconButtonPropsStyled) =>
      props.disabled ? theme.palette.neutral[100] : theme.palette.primary.main,
    color: (props: LabeledIconButtonPropsStyled) =>
      props.disabled ? theme.palette.neutral[600] : theme.palette.common.white,
    "&:hover": {
      backgroundColor: (props: LabeledIconButtonPropsStyled) =>
        props.disabled ? theme.palette.neutral[200] : theme.palette.primary[700],
    },
    "&:focus-visible": {
      boxShadow: `0 0 0 2px ${theme.palette.primary.main}!important`,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      "&:hover": {
        backgroundColor: theme.palette.neutral[500],
      },
    },
    "&:active:focus": {
      backgroundColor: (props: LabeledIconButtonPropsStyled) =>
        props.disabled ? theme.palette.neutral[500] : theme.palette.primary[800],
      boxShadow: "none !important",
    },
    "&:disabled": {
      borderColor: theme.palette.neutral[100],
      backgroundColor: theme.palette.neutral[100],
      color: theme.palette.neutral[600],
    },
  },
  outlined: {
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    "&:hover": {
      borderColor: theme.palette.neutral[100],
      backgroundColor: theme.palette.neutral[100],
    },

    "&:focus-visible": {
      borderColor: theme.palette.primary.main,
      boxShadow: `0 0 0 1px ${theme.palette.primary.main}!important`,
      backgroundColor: theme.palette.common.white,
      "&:hover": {
        backgroundColor: theme.palette.neutral[100],
      },
    },
    "&:active": {
      borderColor: theme.palette.neutral[100],
      backgroundColor: theme.palette.neutral[100],
      boxShadow: `0 0 0 1px ${theme.palette.neutral[100]}!important`,
    },
    "&:disabled": {
      borderColor: theme.palette.neutral[600],
      backgroundColor: theme.palette.neutral[100],
      color: theme.palette.text.secondary,
    },
  },
  text: {
    border: "none",
    color: theme.palette.neutral[600],
    "&:hover": {
      backgroundColor: theme.palette.neutral[100],
    },
    "&:focus-visible": {
      boxShadow: `0 0 0 2px ${theme.palette.primary.main}!important`,
    },
    "&:disabled": {
      color: theme.palette.neutral[600],
    },
  },
  secondary: {
    backgroundColor: theme.palette.neutral[100],
    color: theme.palette.neutral[100],
    "&:hover": {
      backgroundColor: theme.palette.neutral[100],
      color: theme.palette.neutral[100],
    },
    "&:focus-visible": {
      backgroundColor: theme.palette.neutral[100],
      boxShadow: `0 0 0 2px ${theme.palette.primary.main}!important`,
    },
    "&:active": {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.neutral.main,
    },
    "&:active:focus": {
      backgroundColor: theme.palette.common.white,
    },
  },
  contextual: {
    border: `2px solid ${theme.palette.neutral[100]}`,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.neutral[100],
    "&:hover": {
      backgroundColor: theme.palette.neutral[100],
    },
    "&:focus-visible": {
      borderColor: "primary.main",
      backgroundColor: theme.palette.common.white,
      "&:hover:not(:active)": {
        backgroundColor: theme.palette.neutral[100],
      },
    },
    "&:active": {
      borderColor: theme.palette.neutral[100],
      backgroundColor: theme.palette.common.white,
      color: theme.palette.neutral[100],
    },
    "&:disabled": {
      borderColor: theme.palette.neutral[600],
      backgroundColor: theme.palette.neutral[100],
      color: theme.palette.neutral[600],
    },
  },
}));

export default function LabeledIconButton({
  icon,
  label,
  onClick,
  btnVariant,
  disabled,
  iconFontSize = "16px",
  ...buttonProps
}: LabeledIconButtonProps & ButtonProps) {
  const styles = useStyles({ disabled });
  const btnStyle = styles[btnVariant] || styles.filled;

  return (
    <Button
      startIcon={icon ? <FontAwesomeIcon icon={icon} style={{ fontSize: iconFontSize }} /> : null}
      className={`${styles.common} ${btnStyle}`}
      onClick={onClick}
      disabled={disabled}
      {...buttonProps}
    >
      {label}
    </Button>
  );
}
