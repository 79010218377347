import type { LoaderFunctionArgs } from "react-router-dom";

import { OrganizationsService } from "@/services/OrganizationsService";
import store from "@/store";
import { setCurrentOrganizationData } from "@/store/v2/currentOrganization/currentOrganizationSlice";
import { waitForAuthReady } from "@/utils/waitForAuthReady";

export async function currentOrganizationLoader({ params }: LoaderFunctionArgs) {
  return waitForAuthReady(async () => {
    const organizationId = params.organizationId;

    if (!organizationId) {
      throw new Response("Not Found", { status: 404 });
    }

    const existingState = store.getState().currentOrganization;
    if (existingState?.data?.id === +organizationId) {
      return { data: existingState.data };
    }

    const { resp, status, err } = await OrganizationsService.getOrganizationById(+organizationId);
    const organization = resp || null;

    store.dispatch(
      setCurrentOrganizationData({
        data: organization,
        status,
        err,
        noResults: !resp,
      }),
    );

    return { data: organization };
  });
}
