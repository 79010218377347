import { REQUEST_STATUSES } from "@/constants/index.js";

import * as types from "./types.js";
import { getNewList } from "../../../utils/helper.js";

const initialState = {
  members: [],
  status: REQUEST_STATUSES.IDLE,
  err: null,
};

export const membersReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_MEMBERS: {
      const { propertyId, members, status, err, query } = action.payload;
      const newMembers = getNewList(query, state[propertyId].members, members);
      return {
        ...state,
        [propertyId]: {
          members: newMembers,
          status,
          err,
          noResults: !newMembers.length,
        },
      };
    }
    case types.SET_MEMBERS_REQUEST_STATUS: {
      const { propertyId, status } = action.payload;
      return {
        ...state,
        [propertyId]: { ...(state[propertyId] || {}), status },
      };
    }
    case types.RESET_MEMBERS: {
      const { propertyId } = action.payload;
      if (propertyId) {
        return { ...state, [propertyId]: initialState };
      }

      return {};
    }
    default:
      return state;
  }
};
