import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";

import { REQUEST_STATUSES } from "@/constants";
import { OrganizationsService } from "@/services/OrganizationsService";
import type { State } from "@/store/types";

import type { User } from "../currentUser/selectors";

export interface OrganizationUsersState {
  [organizationId: number]: typeof defaultState;
}

const defaultState = {
  data: [] as { user: User }[],
  status: REQUEST_STATUSES.IDLE as REQUEST_STATUSES,
  err: null as unknown,
  noResults: false as boolean,
} as const;

const initialState: OrganizationUsersState = {};

export const fetchOrganizationUsers = createAsyncThunk(
  "organizationUsers/fetchOrganizationUsers",
  async ({ organizationId, query }: { organizationId: number; query?: unknown }) => {
    const { resp, status, err } = await OrganizationsService.getUsers(organizationId, query);
    const { data: users = [], count = 0 } = resp || {};

    return {
      organizationId,
      users,
      count,
      status,
      err,
      query,
    };
  },
);

const organizationUsersSlice = createSlice({
  name: "organizationUsers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrganizationUsers.pending, (state, action) => {
        const organizationId = action.meta.arg.organizationId;
        if (!state[organizationId]) {
          state[organizationId] = { ...defaultState };
        }
        state[organizationId].status = REQUEST_STATUSES.LOADING;
        state[organizationId].err = null;
      })
      .addCase(fetchOrganizationUsers.fulfilled, (state, action) => {
        const { organizationId, users, status, err } = action.payload;
        state[organizationId] = {
          ...state[organizationId],
          data: users,
          status,
          err,
          noResults: users.length === 0,
        };
      })
      .addCase(fetchOrganizationUsers.rejected, (state, action) => {
        const organizationId = action.meta.arg.organizationId;
        if (state[organizationId]) {
          state[organizationId].status = REQUEST_STATUSES.FAILED;
          state[organizationId].err = action.error;
        }
      });
  },
});

export const organizationUsersSelector = (organizationId: number) =>
  createSelector(
    (state: State) => state.organizationUsers,
    (organizationUsers) => ({
      ...defaultState,
      ...organizationUsers?.[organizationId],
    }),
  );

export default organizationUsersSlice.reducer;
