import type React from "react";
declare module "@mui/material/styles/createTypography" {
  interface TypographyOptions {
    body?: React.CSSProperties;
    body_semi_bold?: React.CSSProperties;
    body_strong?: React.CSSProperties;
    body_emphasis?: React.CSSProperties;
    body_link?: React.CSSProperties;
    medium?: React.CSSProperties;
    medium_semi_bold?: React.CSSProperties;
    medium_link?: React.CSSProperties;
    small?: React.CSSProperties;
    small_semi_bold?: React.CSSProperties;
    small_link?: React.CSSProperties;
    context?: React.CSSProperties;
    help?: React.CSSProperties;
    error?: React.CSSProperties;
    success?: React.CSSProperties;
    info?: React.CSSProperties;
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    body: React.CSSProperties;
    body_semi_bold: React.CSSProperties;
    body_strong: React.CSSProperties;
    body_emphasis: React.CSSProperties;
    body_link: React.CSSProperties;
    medium: React.CSSProperties;
    medium_semi_bold: React.CSSProperties;
    medium_link: React.CSSProperties;
    small: React.CSSProperties;
    small_semi_bold: React.CSSProperties;
    small_link: React.CSSProperties;
    context: React.CSSProperties;
    help: React.CSSProperties;
    error: React.CSSProperties;
    success: React.CSSProperties;
    info: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    body?: React.CSSProperties;
    body_semi_bold?: React.CSSProperties;
    body_strong?: React.CSSProperties;
    body_emphasis?: React.CSSProperties;
    body_link?: React.CSSProperties;
    medium?: React.CSSProperties;
    medium_semi_bold?: React.CSSProperties;
    medium_link?: React.CSSProperties;
    small?: React.CSSProperties;
    small_semi_bold?: React.CSSProperties;
    small_link?: React.CSSProperties;
    context?: React.CSSProperties;
    help?: React.CSSProperties;
    error?: React.CSSProperties;
    success?: React.CSSProperties;
    info?: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body: true;
    body_semi_bold: true;
    body_strong: true;
    body_emphasis: true;
    body_link: true;
    medium: true;
    medium_semi_bold: true;
    medium_link: true;
    small: true;
    small_semi_bold: true;
    small_link: true;
    context: true;
    help: true;
    error: true;
    success: true;
    info: true;
  }
}

const FONT_PRIMARY = "Inter";

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: {
    fontWeight: 600,
    fontSize: "56px",
    lineHeight: "72px",
  },
  h2: {
    fontWeight: 400,
    fontSize: "32px",
    lineHeight: "48px",
  },
  h3: {
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "32px",
  },
  h4: {
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "24px",
  },
  body: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
  },
  body_semi_bold: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
  },
  body_strong: {
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
  },
  body_emphasis: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    fontStyle: "italic",
  },
  body_link: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    textDecoration: "underline",
  },
  medium: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "16px",
  },
  medium_semi_bold: {
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "16px",
  },
  medium_link: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "16px",
    textDecoration: "underline",
  },
  small: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
  },
  small_semi_bold: {
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "16px",
  },
  small_link: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
    textDecoration: "underline",
  },
  context: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
  },
  help: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
  },
  error: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
  },
  success: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
  },
  info: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
  },
};

export default typography;
