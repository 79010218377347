import { Chip, type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import type { DateSelectorChip } from "../types";

const useStyles = makeStyles((theme: Theme) => ({
  dateChip: {
    fontFamily: theme.typography.fontFamily,
    cursor: "pointer",
    padding: "8px",
    height: "40px",
    borderRadius: "24px",
    borderColor: `1px solid ${theme.palette.neutral[500]}`,
    ...theme.typography.body,
  },
}));

export default function DateSelectorChip(props: DateSelectorChip) {
  const { label, onClick, sx } = props;
  const styles = useStyles();

  return (
    <Chip
      label={label}
      className={styles.dateChip}
      sx={{
        background: (theme: Theme) => (props.isActive ? theme.palette.primary[100] : theme.palette.neutral[100]),
        color: (theme: Theme) => (props.isActive ? theme.palette.common.black : theme.palette.neutral[600]),
        "&:hover": {
          backgroundColor: (theme: Theme) => (props.isActive ? theme.palette.neutral[100] : theme.palette.neutral[200]),
        },
        ...(sx && { ...sx }),
      }}
      onClick={onClick}
    />
  );
}
