import { REQUEST_STATUSES } from "@/constants/index.js";

import * as types from "./types.js";

interface Action {
  type: string;
  payload: Record<string, unknown>;
}

const initialState = {
  assetCategory: {},
  status: REQUEST_STATUSES.IDLE,
  err: null,
};

export function currentAssetCategoryReducer(state = initialState, action: Action) {
  switch (action.type) {
    case types.GET_CURRENT_ASSET_CATEGORY:
    case types.SET_CURRENT_ASSET_CATEGORY_REQUEST_STATUS:
      return { ...state, ...action.payload };
    case types.RESET_CURRENT_ASSET_CATEGORY:
      return { ...initialState };
    default:
      return state;
  }
}
