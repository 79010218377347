import { differenceInDays, parseISO } from "date-fns";

import { DATE_CHIP_OPTIONS } from "../enums.js";
import type { DateRange, WidgetValueType } from "../types.js";

export default function getCompareText(
  widgetLabel: string,
  dateRange: DateRange,
  chipSelected: string,
  value: WidgetValueType,
) {
  const isCustomDateSelected = chipSelected === DATE_CHIP_OPTIONS.CUSTOM;
  const days = isCustomDateSelected
    ? differenceInDays(parseISO(dateRange.endDate), parseISO(dateRange.startDate)) + 1 // +1 to include the end date
    : null;

  if (value[0] === "N/A") return `No comparison from previous ${isCustomDateSelected ? days + " days" : chipSelected} `;

  if (widgetLabel === "Most Popular Asset Type") {
    return isCustomDateSelected ? `views in last ${days} days` : `views last ${chipSelected}`;
  } else {
    return isCustomDateSelected ? `from previous ${days} days` : `from previous ${chipSelected}`;
  }
}
