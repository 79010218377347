import { createSelector } from "@reduxjs/toolkit";

import { type EXPERIENCE_STATUS, REQUEST_STATUSES } from "@/constants";

export interface MediaShare {
  id?: number;
  hasOpened?: boolean;
  activity?: EXPERIENCE_STATUS;
}

interface MediaShareState {
  mediaShare: MediaShare;
  status: REQUEST_STATUSES;
  err: Error | null;
}

const initialState: MediaShareState = {
  mediaShare: {},
  status: REQUEST_STATUSES.IDLE,
  err: null,
};

interface CurrentMediaShareState {
  currentMediaShare: MediaShareState;
}

export const currentMediaShareSelector = createSelector(
  (state: CurrentMediaShareState) => state.currentMediaShare,
  (currentMediaShare) => {
    return {
      ...initialState,
      ...currentMediaShare,
    };
  },
);
