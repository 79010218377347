export default function changeSortOrder(args: {
  destinationIndex: number;
  sourceIndex: number;
  masterList: { id: number }[];
  filteredList: { id: number }[];
}) {
  const { destinationIndex, sourceIndex, masterList, filteredList } = args;
  const allItemsOrdered = [...masterList];
  const length = { above: 0, below: 0 };

  // Count how many items are above the FIRST element of the filtered list
  for (const { id: currentId } of masterList) {
    const hasAny = filteredList.some(({ id }) => id === currentId);

    if (hasAny) {
      break;
    }

    length.above += 1;
  }

  // Count how many items are below the LAST element of the filtered list
  for (let i = masterList.length - 1; i >= 0; i--) {
    const currentId = masterList[i].id;
    const hasAny = filteredList.some(({ id }) => id === currentId);

    if (hasAny) {
      break;
    }

    length.below += 1;
  }

  let targetElement = null;
  let index = 0;
  const targetId = filteredList[sourceIndex].id;

  // Find target element, and its index
  while (!targetElement || index < allItemsOrdered.length) {
    const { id } = allItemsOrdered[index];

    if (id === targetId) {
      [targetElement] = allItemsOrdered.splice(index, 1);
    }

    index += 1;
  }

  // Ordering from filtered list; do simple math
  let correctDestIndex =
    sourceIndex < destinationIndex ? length.below + destinationIndex + 1 : length.above + destinationIndex;

  // Ordering from unfiltered list
  if (masterList.length === filteredList.length) {
    correctDestIndex = destinationIndex;
  }

  // Insert target element into destination list
  allItemsOrdered.splice(correctDestIndex, 0, targetElement);

  // Filter for items to show in the filtered list
  const filteredItemsOrdered = allItemsOrdered.reduce(
    (acc, cur) => {
      const hasAny = filteredList.some((element) => element.id === cur.id);

      if (hasAny) {
        acc.push(cur);
      }

      return acc;
    },
    [] as { id: number }[],
  );

  return { filtered: filteredItemsOrdered, all: allItemsOrdered };
}
