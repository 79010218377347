/**
 * Object containing functions to generate unique keys for different visualization modes
 * These keys are used to identify and store state for different views
 */
export const VisualBuilderKeys = {
  // Generate key for presentation mode using property ID
  presentation: (propertyId) => propertyId,

  // Generate key for visualization mode using property, experience and user IDs
  visualization: (propertyId, experienceId, userId) => `${propertyId}/${experienceId}/${userId}`,

  // Generate key for public visualization using property and experience IDs
  publicVisualization: (propertyId, experienceId) => `${propertyId}/${experienceId}`,

  // Generate key for single share visualization using property and media item IDs
  publicSingleShareVisualization: (propertyId, mediaItemId) => `${propertyId}/${mediaItemId}`,

  // Generate key for media share visualization using property and share IDs
  publicMediaShareVisualization: (propertyId, shareId) => `${propertyId}/${shareId}`,
};
