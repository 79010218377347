import type { AxiosError } from "axios";

import type { PropertyOrganizationValues } from "@/Dashboard/pages/CreateProperty/types";

export const GET_PROPERTY_ORGANIZATION_VALUES = "GET_PROPERTY_ORGANIZATION_VALUES";
export const SET_PROPERTY_ORGANIZATION_VALUES_REQUEST_STATUS = "SET_PROPERTY_ORGANIZATION_VALUES_REQUEST_STATUS";

interface SuccessResponse {
  resp: PropertyOrganizationValues;
  err: null;
  status: "succeeded";
}

interface ErrorResponse {
  resp: null;
  err: {
    status: number;
    message?: string | AxiosError;
  };
  status: "failed";
}

export type GetOrganizationValuesResponse = SuccessResponse | ErrorResponse;
