import { withAuthenticationRequired } from "@auth0/auth0-react";
import { lazy } from "react";
import { useParams } from "react-router-dom";

import AccessControl from "@/components/AccessControl/AccessControl";
import LoadingSplash from "@/containers/LoadingComponent/LoadingSplash";
import type { User } from "@/store/v2/currentUser/selectors";

const AsyncDoesNotExist = lazy(() => import("../../Dashboard/pages/DoesNotExist/DoesNotExist"));

function validateAccess(user: User, extraAccessData: unknown) {
  const { propertyId } = extraAccessData as Record<string, string | null>;

  if (propertyId && user.propertiesList.includes(Number(propertyId))) {
    return true;
  }

  return !!(user.isAdmin || user.organizationId || (user.propertiesList.length !== 0 && !propertyId));
}

const AuthenticationGuard = ({
  component,
  allowedPermissions = [],
}: {
  component: React.FC;
  allowedPermissions?: string[];
}) => {
  const { propertyId = null } = useParams();
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <LoadingSplash />,
  });

  const AuthorizedComponent = () => (
    <AccessControl
      allowedPermissions={allowedPermissions}
      accessCheck={validateAccess}
      extraAccessData={{ propertyId }}
      renderNoAccess={() => <AsyncDoesNotExist message="You don't have credentials to access this page." />}
    >
      <Component />
    </AccessControl>
  );

  return <AuthorizedComponent />;
};

export default AuthenticationGuard;
